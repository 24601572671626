import React from "react"
import AppLayout from "../../components/app/app-components/layout"
import MyExamsContainer from "../../components/app/containers/MyExamsContainer"

const ExamList = ({ path }) => {
  return (
    <AppLayout isBackButton={true} title="My Enrollments" path={path}>
      <div className="px-4 pb-4">
        <MyExamsContainer isViewAllButton={false} limit={999} />
      </div>
    </AppLayout>
  )
}

export default ExamList
